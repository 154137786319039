import styles from '../styles/pages/Dashboard.module.css';

import { useOutletContext } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { gql, useSubscription } from '@apollo/client'

const PRODUCT_SUBSCRIPTION = gql`
subscription getProducts {
  products {
    votes {
      vote
      user_id
      product_id
    }
    id
    name
    brand
    category
    created_at
    description
    gender
    price_currency
    price_value
    drop_date
    url
    image_url
    code
    votes_aggregate {
      aggregate {
        sum {
          vote
        }
      }
    }
    promo_price_value
    promo_end_date
  }
}

`

const Dashboard = () => {
  const { user } = useOutletContext();
  const { data } = useSubscription(PRODUCT_SUBSCRIPTION);

  return (
    <table className="table">
      <thead className="thead-dark">
        <tr>
          <th scope="col">Zdjęcie</th>
          <th scope="col">Nazwa produktu</th>
          <th scope="col">Opis</th>
          <th scope="col">Cena</th>
          <th scope="col">Cena promocyjna</th>
          <th scope="col">Koniec promocji</th>
          <th scope="col">Marka</th>
          <th scope="col">Kategoria</th>
          <th scope="col">Płeć</th>
          <th scope="col">Data dropu</th>
          <th scope="col">Akcje</th>
        </tr>
      </thead>
      <tbody>
        {data?.products.map((product, i) => {
          return (
            <tr>
              <td scope="row"><img width="50px" class="img-fluid" src={product.image_url} alt="..." className="img-thumbnail"/></td>
              <td scope="col">{product.name}</td>
              <td scope="col">{product.description}</td>
              <td scope="col">{product.price_value}</td>
              <td scope="col">{product.promo_price_value}</td>
              <td scope="col">{product.promo_end_ate}</td>
              <td scope="col">{product.brand}</td>
              <td scope="col">{product.category}</td>
              <td scope="col">{product.gender}</td>
              <td scope="col">{product.dropDate}</td>
              <td scope="col">Akcje</td>
            </tr>
          )
        })}
      </tbody>
    </table>
  );
};

export default Dashboard;
